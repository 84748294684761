import React from 'react';
import { Grid } from 'semantic-ui-react';

import './Bar.css'

import image from '../images/beaker.jpg';

const Bar = (props) => {
    const bg = '#DADADA';
    const activeItem = props.items.filter((i) => { return i.id === props.value })[0];   
    const color = activeItem === undefined ? bg : activeItem.effect.color;
    console.log(image);

    return (
        <Grid>
            <Grid.Row>
                <Grid.Column widescreen="7"></Grid.Column>
                <Grid.Column widescreen="2">
                    <Grid style={{marginTop: '0px', marginBottom: '0px'}}>
                    {
                        props.items.map((item) => (
                            <Grid.Row key={item.id} className="segment" style={
                                {
                                    backgroundColor:props.items.length-item.id < props.value ? color : bg 
                                }}>&nbsp;</Grid.Row>
                        ))
                    }
                    </Grid>
                </Grid.Column>
                <Grid.Column widescreen="7">
                    <img src={image} style={{width:'40%'}} alt=""></img>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    )
}

export default Bar;