import React from 'react';
import { Container } from 'semantic-ui-react';

import Bar from './Bar';

const Thermometer = (props) => {
    
    return (
        <Container>
            <Bar />
        </Container>
    )
}
export default Thermometer;