import React from 'react';
import {Grid, Button, Responsive, Form} from 'semantic-ui-react';

const Controls = (props) => {
    const items = props.config.items.map((value,index) => {
        return (
            <Grid.Column textAlign='center' key={value.id}>
                <Button name={value.id} onClick={props.onClick}>{value.display}</Button>
            </Grid.Column>);
    });
    return (
        <Responsive>
            <Form>
                <Grid columns='equal'>
                    <Grid.Row>
                        <Grid.Column></Grid.Column>
                        {items}
                        <Grid.Column></Grid.Column>
                    </Grid.Row>
                </Grid>
            </Form>
        </Responsive>
    )
}

export default Controls;