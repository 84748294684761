import React from 'react'

import { Modal, Icon, Button, TextArea, Header, Form} from "semantic-ui-react"

const Confirmation = (props) => {
    const handleSubmit = (e, data) => {
        props.onSubmit(data.value);
    }

    return (
    <Modal open={props.opened} basic size='small'>
        <Header icon='question' content={`Change ${props.config.title}?`} />
        <Modal.Content>
            <Form>
                <TextArea id='reason' placeholder='Reason, 140 characters max, optional' onChange={props.onChange} maxLength="140" value={props.data.reason}/>
                <p>Are you sure you want to change the {props.config.title}?</p>
            </Form>
        </Modal.Content>
        <Modal.Actions>
        <Button basic color='red' value='no' inverted onClick={handleSubmit}>
            <Icon name='remove' /> No
        </Button>
        <Button color='green' value='yes' inverted onClick={handleSubmit}>
            <Icon name='checkmark' /> Yes
        </Button>
        </Modal.Actions>
    </Modal>
    )
}

export default Confirmation