import React from 'react';
import { Segment } from 'semantic-ui-react';

const Default = (props) => {
    if (props.disabled)
        return null;
    else
    {
        return (
            <Segment>
                Welcome to the Level Report. This web application is designed to show the current "level" of something.
                <ol>
                    <li><a href="https://beaker.level.report">Beaker Level</a></li>
                </ol>
            </Segment>
        )
    }
}

export default Default;