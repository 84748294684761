import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import thunkMiddleware from 'redux-thunk';
import { logger } from 'redux-logger';
import { createStore, applyMiddleware } from 'redux'
import * as serviceWorker from './serviceWorker';

import { loadConfig } from './actions';
import rootReducer from './reducers';
import AsyncApp from './containers/AsyncApp';

const store = createStore(rootReducer, applyMiddleware(
    thunkMiddleware,
    logger
));

var subdomain = window.location.host.substring(0,window.location.host.indexOf('.')) || window.location.pathname.replace('/','');
store.dispatch(loadConfig(subdomain));
ReactDOM.render(<Provider store={store}><AsyncApp subdomain={subdomain}/></Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
