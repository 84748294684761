import socketIOClient from "socket.io-client";

export const REQUEST_CONFIG = 'REQUEST_CONFIG';
export const RECEIVE_CONFIG = 'RECEIVE_CONFIG';
export const RECEIVE_VOTES = 'RECEIVE_VOTES';
export const SEND_VOTE = 'SEND_VOTE';
export const UPDATE_DATA = 'UPDATE_DATA';
export const CONNECT = 'CONNECT';
export const SHOW_CONFIRMATION = 'SHOW_CONFIRMATION';
export const HIDE_CONFIRMATION = 'HIDE_CONFIRMATION';

let socket = undefined;

function startConnection(apiRoot) {
    return dispatch => {
        const args = {};
        const secureMode = apiRoot.includes('https');
        if (secureMode)
            args.secure = true;
        socket = socketIOClient(apiRoot);
        return {
            type: CONNECT,
            apiRoot
        }
    } 
}

export function loadConfig(configName) {
    return dispatch => {
        if (socket === undefined)
            //dispatch(startConnection('http://100.115.92.194:3001/'));
            dispatch(startConnection('https://level-stream.herokuapp.com/'));
        dispatch(requestConfig(configName));
        socket.on("LOAD", data => {
            dispatch(receiveConfig(configName, data));
        })
        socket.on("UPDATE", data => {
            console.log(data);
            const [instance,value,reason] = JSON.parse(data);
            dispatch(receiveVotes(instance,parseInt(value), reason));
        });
        socket.on('connect', data => {
            socket.emit("INIT", configName);
        });
        socket.on("disconnect", data => {
            // TODO: Display in interface?
            console.log("Lost connection to database, attempting to reconnect...");
        });
    }
}

export function showConfirmation(value) {
    return {
        type: SHOW_CONFIRMATION,
        value
    }
}

export function hideConfirmation() {
    return {
        type: HIDE_CONFIRMATION
    }
}

export function sendVote(configName, value, reason) {
    socket.emit("VOTE", JSON.stringify([configName,value,reason]));
    return {
        type: SEND_VOTE
    }
}

export function updateData(name, value) {
    return {
        type: UPDATE_DATA,
        name,
        value
    }
}

function requestConfig(configName) {
    return {
        type: REQUEST_CONFIG,
        name: configName
    }
}

function receiveConfig(configName, data) {
    return {
        type: RECEIVE_CONFIG,
        name: configName,
        config: data,
        receivedAt: Date.now()
    }
}

function receiveVotes(instance,value,reason) {
    return {
        type: RECEIVE_VOTES,
        instance: instance,
        value: value,
        reason: reason,
        receivedAt: Date.now()
    }
}