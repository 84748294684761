import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Segment, Icon } from 'semantic-ui-react';

import { sendVote, updateData, showConfirmation, hideConfirmation } from '../actions';

import Controls from '../Controls';
import VisualizationController from '../vis/VisualizationController';
import Confirmation from '../Confirmation';

const Level = (props) => {
    const config = props.config;
    const title = config.title;
    const instance = config.instance;

    const reason = config.value.reason;

    const reasonElement = (reason !== null && reason !== undefined) && reason.length > 0 ? <Segment size='tiny'><b>Reason:</b> {reason}</Segment> : null;
    
    useEffect(() => {
        window.document.title = `${jsUcfirst(instance)} Level Report`
    },[title]);

    const handleConfirm = (e, control) => {
        props.dispatch(showConfirmation(control.name))
    }

    const handleSubmit = (value) => {
        if (value === 'yes')
        {
            const reason = props.data.reason || ''
            props.dispatch(sendVote(props.name,props.pendingValue,reason));
            props.dispatch(updateData('reason',''));
        }
        props.dispatch(hideConfirmation())
    }

    const handleChange = (e,control) => {
        props.dispatch(updateData(control.id,control.value))
    }

    const rssUrl = `${props.apiRoot}${config.instance}/feed/rss`;

    return(
        <Segment>
            <Segment textAlign='center' size='huge'>{title}<a href={rssUrl}><Icon color='orange' name='rss square' /></a></Segment>
            <VisualizationController config={config} />
            {reasonElement}
            <Controls config={config} data={props.data} onClick={handleConfirm} onChange={handleChange}></Controls>
            <Confirmation opened={props.confirming} data={props.data} config={config} onChange={handleChange} onSubmit={handleSubmit}></Confirmation>
        </Segment>);
}

Level.propTypes = {
    isFetching: PropTypes.bool.isRequired,
    confirming: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
    config: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired,
    apiRoot: PropTypes.string.isRequired
}


function jsUcfirst(string) 
{
    return string.charAt(0).toUpperCase() + string.slice(1);
}

function mapStateToProps(state) {
    const { isFetching, confirming, pendingValue, config, data, name, lastUpdated, apiRoot} = state || {
        isFetching: true,
        confirming: false,
        pendingValue: undefined,
        name: '',
        config: {items:[]},
        data: {},
        apiRoot: ''
    }
    return {
        isFetching,
        confirming,
        pendingValue,
        name,
        lastUpdated,
        config,
        data,
        apiRoot
    }
}

export default connect(mapStateToProps)(Level);