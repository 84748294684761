import React from 'react';
// Visualizations
import Thermometer from './Thermometer';
import Bar from './Bar';

const VisualizationController = (props) => {
    const config = props.config || {
        items: []
    };

    switch(config.visualization)
    {
        case 'Thermometer':
            return (<Thermometer value={config.value.value} reason={config.value.reason} items={config.items} />);
        case 'Bar':
            return (<Bar value={config.value.value} reason={config.value.reason} items={config.items} />);
        default:
            return (null);
    }
}

export default VisualizationController