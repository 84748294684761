import { 
    CONNECT,
    REQUEST_CONFIG,
    RECEIVE_CONFIG,
    RECEIVE_VOTES,
    SEND_VOTE,
    UPDATE_DATA,
    SHOW_CONFIRMATION,
    HIDE_CONFIRMATION
} from './actions';

function config(state = {
    apiRoot: 'https://level-stream.herokuapp.com/',
    isFetching: false,
    confirming: false,
    name: '',
    data: {},
    config: {
        items:[],
        value:{
            value:undefined,
            reason:''
        }
    },
    compare: {
        items:[]
    }
}, action)
{
    switch(action.type)
    {
        case CONNECT:
            return Object.assign({}, state, {
                apiRoot: action.apiRoot
            });
        case REQUEST_CONFIG:
            return Object.assign({}, state, {
                isFetching: true
            });
        case RECEIVE_CONFIG:
            return Object.assign({}, state, {
                isFetching: false,
                name: action.name,
                config: action.config || {
                    items:[]
                },
                lastUpdated: action.receivedAt
            });
        case RECEIVE_VOTES:
            return Object.assign({}, state, {
                // TODO: When implementing 'compare', need to use action.instance
                config: Object.assign({}, state.config, {
                    value: Object.assign({}, state.config.value, {
                        value: action.value,
                        reason: action.reason
                    })
                }),
                lastUpdated: action.receivedAt
            });
        case UPDATE_DATA:
            return Object.assign({}, state, {
                data: Object.assign({}, state.data, {
                    [action.name]: action.value
                })
            });
        case SEND_VOTE:
            return Object.assign({}, state, {
                data: Object.assign({}, state.data, {})
            });
        case SHOW_CONFIRMATION:
            return Object.assign({}, state, {
                confirming: true,
                pendingValue: action.value
            });
        case HIDE_CONFIRMATION:
            return Object.assign({}, state, {
                confirming: false,
                pendingValue: undefined
            });
        default:
            return state;
    }    
}

const rootReducer = config;

export default rootReducer;