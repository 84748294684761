import React from 'react';
import {Grid, Segment, Responsive, Loader} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'; 

import Default from '../Default';
import Level from './Level';

const AsyncApp = (props) => {
    if (props.subdomain === '')
        return (<Default></Default>)
    else if (props.config.title === undefined)
        return (
            <Loader active>
                <Segment vertical>
                    Connecting to the {props.subdomain} level...
                </Segment>
            </Loader>
        )
    else if (props.compare.title !== undefined)
        return (
            <Segment.Group>
                <Responsive as={Segment} minWidth={790}>
                    <Grid columns={2}>
                        <Grid.Column>
                            <Level name={props.name}></Level>
                        </Grid.Column>
                        <Grid.Column>
                            <Level name={props.name}></Level>
                        </Grid.Column>
                    </Grid>
                </Responsive>
                <Responsive as={Segment} maxWidth={789}>
                    <Level name={props.name}></Level>
                    <Level name={props.name}></Level>
                </Responsive>
            </Segment.Group>
        )
    else
        return (<Level name={props.name}></Level>)
}

AsyncApp.propTypes = {
    isFetching: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
    config: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired,
    compare: PropTypes.object.isRequired
}

function mapStateToProps(state) {
    const { isFetching, config, data, compare, name, lastUpdated} = state || {
        isFetching: true,
        name: '',
        data: {reason:''},
        config: {items:[]},
        compare: {items:[]}
    }
    return {
        isFetching,
        name,
        data,
        lastUpdated,
        config,
        compare
    }
}

export default connect(mapStateToProps)(AsyncApp);